import {defineStore} from "pinia";

export const useProviderConsoleStore = defineStore('providerConsoleStore', () => {
    // State
    const searchTerm = ref("");
    const isDrawerOpen = ref(false);

    // const currentClaimsFilter = ref("all")

    // Actions
    function clearSearchTerm(){
        searchTerm.value = "";
    }
    function openDrawer() {
        isDrawerOpen.value = true;
    }
    function closeDrawer(){
        isDrawerOpen.value = false
    }
    function toggleDrawer(){
        isDrawerOpen.value = !isDrawerOpen.value;
    }

    // function viewAllClaims(){
    //     currentClaimsFilter.value = "all"
    // }
    // function viewPaidClaims() {
    //     currentClaimsFilter.value = "paid"
    // }

    return {
        searchTerm, isDrawerOpen,
        clearSearchTerm, openDrawer, closeDrawer, toggleDrawer
    }
})
